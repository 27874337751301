.block{
  display: block;
  width: 100%;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.grid-container{
  display: grid;
  grid-template-columns: 1fr auto;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 3px solid #ec3535; /* Change color if needed */
  margin: 0 10px;
}

.divider span {
  color: #ec3535; /* Change text color if needed */
  font-size: 14px; /* Change font size if needed */
}

.comments-drawer .ant-drawer-body {
  padding: 0;
}
.comments-drawer .upload-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 60px;
  position: relative;
}

.comments-drawer .upload-container .comments-list {
  padding: 20px 4px;
  overflow-y: auto;
}

.comments-drawer .upload-container .bottom-section {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.comments-drawer .upload-container .add-comment .input-section {
  padding: 12px 20px;
  /* border-top: 1px solid #d9d9d9; */
}

.comments-drawer .upload-container .add-comment .sub-input-section {
  display: flex;
  align-items: center;
}

.comments-drawer .upload-container.drag-active::after {
  content: "";
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background-color: #188fff44;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 2px solid #1890ff;
  border-radius: 4px;
}

.comments-drawer .drop-message {
  display: none;
}

.comments-drawer .drop-message.drag-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1890ff;
  border-radius: 4px;
  width: calc(100% - 24px);
  height: 145px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1000;
}

.comments-drawer .drop-message.drag-active svg {
  width: 40px;
  height: 40px;
}

.comments-drawer .drop-message.drag-active p {
  font-size: 16px;
  margin-top: 4px;
}

.comments-drawer .upload-button {
  width: 100%;
  border: none;
  background-color: #f0f5ff;
}

.comments-drawer .upload-button svg {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  color: #000;
}

.comments-drawer .upload-button svg * {
  fill: #000 !important;
}

.comment-action-dropdown {
  z-index: 10000000001;
  min-width: 80px;
}

.comment-action-space{
  margin-top: 16px;
}

.comment-action {
  margin-top: 16px;
  display: none;
  opacity: 0;
  transition: opacity 100ms ease-in;
  border: none;
}

.space-left{
  padding: 0 16px;
}

.comment-list-item.ant-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 5px;
  transition: background-color 100ms ease-in;
}

.comment-list-item:hover,
.comment-list-item:focus-within,
.comment-list-item.comment-being-edited {
  background-color: #e9edf0;
}
/* .comment-list-item:hover .comment-action,
.comment-list-item:focus-within .comment-action {
  display: block;
  opacity: 1;
} */

.comment-list-item .comment {
  width: fit-content;
  background-color: transparent;
}

.comment-list-item.align-comment-right {
  align-self: flex-end;
  /* flex-direction: row-reverse; */
}

.comment-list-item.align-comment-left {
  align-self:flex-start;
  /* flex-direction: row-reverse; */
}

.comment-list-item.align-comment-right .ant-comment .ant-comment-inner {
  flex-direction: row-reverse;
}

.comment-list-item.align-comment-right
  .ant-comment
  .ant-comment-inner
  .ant-comment-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.comment-list-item.align-comment-right
  .ant-comment
  .ant-comment-content-author-time {
  padding-left: 8px;
  padding-right: 0;
}

.comment-list-item .ant-comment .ant-comment-content-author-name {
  padding: 0 8px;
}

.comment-list-item.align-comment-right .ant-comment-content-author {
  justify-content: flex-end;
}

.comment-list-item.align-comment-right .ant-comment-content-detail > p {
  width: fit-content;
  margin-left: auto;
  max-width: 100%;
}

.comment-list-item .request-edit-approved,
.comment-list-item .request-edit-rejected,
.comment-list-item .request-confirmation {
  margin-right: 8px;
  padding: 2px 12px;
  font-size: 11px;
  color: #fff;
  border-radius: 100px;
}

.comment-container{
  padding: 5px 10px;
  /* margin-top:30px; */
  /* position: relative; */
}

/* .comment-unread{
  border: 0.4px solid rgba(255, 65, 18, 0.592);
} */

.message{
  position: absolute;
  top: 0px;
  left: -10px;
  padding: 2px 6px;
  font-size: 10px;
  color: #fff;
  background: #ff4012;
  width: 80px;
  font-weight: bold;
  transform: translateY(-50%);
}

.message-parent{
  top:20px;
  transform: translateY(-50%);
}

.comment-list-item .ant-comment-content-author-time {
  display: flex;
  align-items: center;
}

.request-edit-approved,.request-confirmation,.request-edit-rejected {
  margin-right: 8px;
  padding: 4px 12px;
  font-size: 11px;
  border-radius: 100px;
  color: #000;
  font-weight: bold;
  margin-left: 5px;
}

.request-edit-approved-color{
  background-color: #96d360;
}


.request-edit-rejected-color {
  background-color: #ff4012;
}

.request-confirmation-color{
  background-color: #f1c21b
}

/* .request-confirmation {
  color: #000;
  background-color: #f1c21b;
  margin-right: 0;
  margin-left: 8px;
  padding: 4px 12px;
  font-size: 11px;
  border-radius: 50px;
  font-weight: bold;
} */

.comment-list-item .reply-to-button {
  border: transparent;
  position: relative;
  margin-left: 8px;
}

.comment-list-item .reply-to-button svg {
  position: absolute;
  top: 4.5px;
  left: 5px;
  width: 13px;
  height: 13px;
}

.comment-list-item .reply-to-preview {
  display: flex;
  flex-direction: column;
  color: #828282;
  font-style: italic;
  padding: 10px 10px 10px 20px;
  border: 1px dashed #828282;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.comment-list-item .reply-to-preview::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  width: 7px;
  height: calc(100% + 2px);
  background-color: #828282;
  border-radius: 10px 0px 0px 10px;
}

.comment-list-item .reply-to-preview .author {
  font-size: 13px;
}

.comment-list-item .reply-to-preview .content {
  font-size: 12px;
}

.file-attachment-with-text-container {
  width: 300px;
  height: 100%;
  padding: 5px;
  gap: 8px;
}

.container-width{
  width: 300px;
}

.file-attachment-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.preview-comment {
  min-height: 30px;
  width: 600px;
  overflow: hidden;
  margin-bottom: 3px;
  text-justify: inter-word;
}

.preview-comment > p {
  width: fit-content;
  max-width: 100%;
}

@media only screen and (max-width: 1366px) {
  .preview-comment {
    width: 400px;
  }
}

@media only screen and (max-width: 830px) {
  .preview-comment {
    width: 200px;
  }
}

@media only screen and (max-width: 350px) {
  .preview-comment {
    width: 100px;
  }
}

.file-attachments {
  width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.file-attachment {
  direction: ltr;
  width: 45%;
  min-height: 95px;
  max-height: 145px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #1890ff;
  cursor: pointer;
  position: relative;
  margin: 4px;
}

.file-attachment.is-image {
  grid-column: 1/4;
}

.file-attachment:hover .file-preview {
  visibility: visible;
  opacity: 1;
}

.file-attachment svg {
  font-size: 28px;
}

.file-attachment p {
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  text-align: center !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.file-preview {
  visibility: hidden;
  opacity: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.45);
  transition: color 150ms ease-in;
}

.file-preview .anticon {
  margin: 0 4px;
}

.file-preview .anticon svg {
  width: 20px;
}

.file-preview .anticon:hover {
  color: #fff;
}

.file-attachment .preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-uploading {
  float: right;
  display: block;
  background-color: var(--main-color);
  width: 120px;
  height: 3px;
  margin-top: 10px;
  border-radius: 100px;
}

.file-uploading__element {
  width: 120px;
  height: 3px;
  background: #188fff44;
  border-radius: 100px;
}

.file-uploading__element:before {
  content: "";
  display: block;
  background-color: #1890ff;
  height: 3px;
  width: 0;
  animation: getWidth 2s ease-in infinite;
  border-radius: 100px;
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}

.general-message-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 60px;
}

.general-message-info p {
  color: #bfc6cc;
  font-size: 16px;
  text-align: center;
  margin-top: 60px;
}

.preview {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
}

.preview-img-container{
  display: inline-flex;
  align-items: start;
  margin: 0 20px 10px 0;
  position: relative;
}

.preview-img {
  width: 80px;
  height: 80px;
  border-radius: 15%;
  object-fit: cover; 
}

.preview.preview-img-cancel {
  position: absolute;
  top: 25%;
  right: 890px;
  cursor: pointer;
  opacity: 0.7;
}

.preview-file-container {
  padding: 5px 20px 10px 20px;
  position: relative;
}

.preview.preview-file-cancel {
  /* position: absolute; */
  top: 0;
  right: 890px;
  cursor: pointer;
  opacity: 0.7;
}

.request-edit-approval {
  background-color: #f3f4f8;
  border-radius: 5px;
  margin: 4px 0px 32px;
  padding: 16px;
  width: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.request-edit-approval .title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.request-edit-approval .title .anticon {
  font-size: 20px;
  margin-right: 4px;
}

.request-edit-approval .actions button {
  min-width: 100px;
  margin: 0 8px;
}

.reply-to-comment {
  color: #fff;
  background-color: #22272b;
  border-radius: 8px 8px 0 0;
  padding: 12px 20px 20px;
  display: flex;
  flex-direction: column;
}

.reply-to-comment .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #fff9;
}

.reply-to-comment p {
  margin-bottom: 0;
}

.reply-to-comment .header .anticon {
  cursor: pointer;
}

.reply-to-comment .comment {
  background-color: #353a40;
  padding: 8px;
  border-radius: 5px;
}

.reply-to-comment .comment .author {
  color: #fff9;
  font-size: 12px;
  margin-bottom: 8px;
}

.pulse-animate {
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    background-color: #40a9ff66;
    box-shadow: 0 0 0 2px #40a9ff66;
  }

  100% {
    background-color: #fff;
    box-shadow: 0 0 0 10px #fff;
  }
}
