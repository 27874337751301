#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.feedback .image-wrapper>img {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  background: #fff;
  border-radius: 25px;
}

.alert-banner .ant-alert-close-icon .anticon-close {
  color: white;
  /* margin-right: 15px; */
}

.modal-data {
  text-align: center;
}

.modal-data>h1 {
  margin-top: 20px;
  margin-bottom: 25px;
}

.modal-data>p {
  font-size: 16px;
  color: #a0a0a0;
}

.modal-data .feedback-icon-wrapper {
  display: flex;
  justify-content: space-around;
  margin: 30px 60px;
}

.modal-data .opacity .feedback-icon {
  opacity: 0.3;
}

.modal-data .feedback-icon-wrapper:hover>.feedback-icon {
  opacity: 0.3;
}

.modal-data .feedback-icon-wrapper .feedback-icon {
  width: 45px;
  height: auto;
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 99;
}

.modal-data .feedback-icon-wrapper .feedback-icon>span {
  position: absolute;
  font-size: 12px;
  color: #999;
  text-align: center;
  top: 90px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.modal-data .feedback-icon-wrapper .feedback-icon:hover {
  opacity: 1;
}

.modal-data .feedback-icon-wrapper .feedback-icon:hover>span {
  top: 50px;
  opacity: 1;
}

.modal-data .opacity .selected {
  opacity: 1;
}

.modal-data .opacity .selected>span {
  opacity: 1;
  top: 50px;
}

.modal-footer {
  margin-top: 30px;
  text-align: right;
}

.modal-footer>button {
  width: 100px;
}

.table-component-wrapper {
  position: relative;
}

.table-component-wrapper .disabled {
  position: absolute;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #ddd;
  cursor: not-allowed;
}

.comments-drawer-tab-navigate:hover {
  background-color: rgba(0, 0, 0, 0.018);
}

.comments-drawer-back-btn {
  margin-left: -8px;
}

.is-unread .ant-badge-count {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 2px #ff4d5095;
  }

  100% {
    box-shadow: 0 0 0 10px transparent;
  }
}