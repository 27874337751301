.request-banner.ant-alert {
  background-color: #dde0e5;
  border-color: #dde0e5;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
}

.request-banner.bg-warning {
  background-color: #f1c21b;
}

.request-banner .ant-alert-message {
  max-width: 400px;
}

.request-banner button.ant-alert-close-icon {
  font-size: 20px;
  line-height: 0;
  position: relative !important;
  right: initial !important;
  top: initial !important;
}

.request-banner .ant-alert-close-text {
  color: #000;
}

.request-banner.paraplanner-request-banner .ant-alert-message {
  width: 100%;
  max-width: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
}

.width-100 {
  width: 100%;
}

.black-btn {
  background-color: #000;
  border-color: #000;
  margin-right: 12px;
}

.black-btn,
.black-btn:focus,
.black-btn:active,
.black-btn:hover {
  background-color: #dde0e5;
  color: #071908;
  border-color: #071908;
}

.black-btn,
.black-btn:disabled {
  background-color: #071908;
  color: #fff;
  border-color: #071908;
}

.paraplanner-select-option {
  position: relative;
}
.paraplanner-select-option:hover:after {
  content: "Add";
  height: 22px;
  padding: 0 8px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #4b4f52;
  background-color: #dde1e4;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.9;
}

.request-banner-actions {
  align-items: center;
  display: flex;
}

.advance-btn {
  align-items: center;
  display: flex;
}

.advance-btn > svg {
  margin-right: 8px;
}

.reasons-drawer-checkbox-group {
  display: flex;
  flex-direction: column;
}

.reasons-drawer-checkbox-group .ant-checkbox-group-item {
  padding-bottom: 6px;
}

.file-uploader.ant-upload,
.file-uploader .ant-upload {
  margin-top: 0 !important;
}

.file-uploader .ant-upload-text {
  margin-bottom: 0 !important;
  font-size: 14px !important;
}

.file-uploader .ant-upload-disabled .ant-upload-text {
  color: rgba(0, 0, 0, 0.5) !important;
}

.file-uploader .ant-upload-disabled svg * {
  fill: rgba(0, 0, 0, 0.5) !important;
}

.complete-drawer .ant-drawer-body {
  padding: 0;
}

.complete-drawer .ant-drawer-body > .ant-row {
  height: 100%;
}

.complete-drawer .ant-drawer-body > .ant-row > .ant-col {
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}

.complete-drawer .email-input .ql-editor {
  min-height: 240px;
}

.complete-drawer .email-input .ql-editor ul,
.complete-drawer .email-input .ql-editor ol {
  padding-left: 0;
}

.complete-drawer .fee-confirmation .title-row {
  background-color: #f5f5f5;
  line-height: 1.15;
  padding: 12px !important;
}

.complete-drawer h5.ant-typography {
  margin-bottom: 8px !important;
}

.disable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d9d9d999;
  z-index: 1;
  cursor: not-allowed;
}

/* React-Quill CSS fix */

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
  content: "Small";
  font-size: 0.75em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
  content: "Normal";
  font-size: 1em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: "Large";
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
  content: "Huge";
  font-size: 2.5em !important;
}
