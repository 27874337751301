.margin-bottom-10 {
  margin-bottom: 10px !important;
}

@import "../node_modules/rsuite/dist/rsuite.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #f1f6fa;
}

.ant-tabs-nav {
  padding-top: 24px !important;
  background-color: #ffffff !important;
}

.other-tabs {
  padding: 20px;
  background-color: #ffffff;
}

.ant-tabs-content.ant-tabs-content-top {
  padding: 20px;
  background-color: #f0f2f5;
}

.no-padding-tabs {
  background-color: #f0f2f5;
}

.strategies .ant-table-cell {
  border-bottom: none !important;
}

.no-padding .ant-tabs-content {
  padding: 0 !important;
}

/* styles for margins */
.strategies .custom-divider {
  margin: 0px !important;
}

.strategies .custom-divider .ant-input-number-handler-wrap {
  display: none !important;
}

.ant-table-tbody>tr.ant-table-row-level-0:hover>td {
  background: unset;
}

.strategies .ant-table-tbody>tr .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px -10px !important;
}

.ant-notification {
  z-index: 99999999999;
}

.ant-message {
  z-index: 100000000000 !important;
}

/* disable input number step */
.ant-input-number-handler-wrap {
  display: none;
}

/* Loaders */
.rectangle-loader {
  width: 100%;
  min-height: 120px;
}

.rectangle-loader .ant-skeleton-input {
  height: 100%;
}

.ant-table-placeholder .ant-table-cell {
  border-bottom: none;
}

.ant-tabs.ant-tabs-top {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ant-layout.site-layout {
  position: relative;
  padding-bottom: 70px;
}

.ant-layout-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.on-hold-request .ant-table-cell {
  background-color: #fffcf3;
}

.ant-table .ant-table-tbody>.on-hold-request:hover>td.ant-table-cell {
  background-color: #fffcf3 !important;
}

.status-sub-text {
  color: #f29c40;
}

.ant-drawer-header {
  font-weight: 400 !important;
}

.ant-drawer-header h4 {
  font-weight: 400 !important;
}

.ant-input[disabled],
.ant-input-disabled,
.ant-select-disabled .ant-select-selector,
.ant-btn[disabled],
.ant-input-number-disabled .ant-input-number-input {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.ant-radio-disabled+span,
.ant-checkbox-disabled+span {
  color: rgba(0, 0, 0, 0.5) !important;
}

.disabled-icon svg * {
  fill: #d9d9d9 !important;
}

.ant-breadcrumb>span:last-child p {
  color: rgba(0, 0, 0, 0.85);
}

.ant-breadcrumb p {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
  cursor: pointer;
  display: inline;
}

.strategies .row-title {
  margin-bottom: 20px;
}

.strategies .row-title .ant-typography {
  margin-bottom: 0;
  margin-left: 16px;
  color: #000;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000000;
}

.rs-icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
}