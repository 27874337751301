.pricing-ui-container {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  margin-bottom: 16px;
}

.pricing-ui-container .ant-row {
  border-bottom: 1px solid #d9d9d9;
}

.pricing-ui-container .ant-col {
  border-right: 1px solid #d9d9d9;
  padding: 0 12px;
}

.pricing-ui-container .ant-col.amount {
  display: flex;
  justify-content: space-between;
  vertical-align: bottom;
}

.pricing-ui-container .ant-col.no-of {
  border-top: 1px solid #d9d9d9;
}

.pricing-ui-container .ant-col.no-of:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.pricing-ui-container .ant-input-number {
  width: 100%;
}

.pricing-ui-container .ant-input-number input {
  text-align: center;
}

.pricing-ui-container .header .ant-select-selector {
  padding-left: 0;
}

.pricing-ui-container .total {
  background-color: #f5f5f5;
}

.pricing-ui-container .ant-col.amount.surcharge {
  color: red;
}

.pricing-ui-container .ant-col.amount.discount {
  color: green;
}
